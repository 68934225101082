<template>
  <div class="page rules">
    <BreadCrumb />
    <div class="page-body">
      <el-row>
        <el-col
          :span="7"
          class="left"
        >
          <div class="custom-tree-container">
            <div class="block">
              <p>分类</p>
              <el-tree
                :data="treeData"
                node-key="id"
                highlight-current
                :default-expanded-keys="[1]"
                :expand-on-click-node="false"
                :render-content="renderContent"
                :props="defaultProps"
                @node-click="handleNodeClick"
              />
            </div>
          </div>
        </el-col>
        <el-col
          :span="16"
          class="right"
        >
          <el-table
            id="test"
            v-loading="loading"
            :data="tableData"
            stripe
            fit
          >
            <el-table-column
              prop="templateName"
              label="规则名称"
            />
            <el-table-column
              prop="createBy"
              label="创建人"
            />
            <el-table-column
              prop="createTime"
              label="创建时间"
            />
            <el-table-column
              prop="updateTime"
              label="更新时间"
            />
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb';

export default {
    'name': 'RuleClassify',
    'components': {
        BreadCrumb
    },
    data() {
        return {
            'loading': true,
            'treeData': '',
            'tableData': '',
            'defaultProps': {
                'children': 'children',
                'label': 'text'
            }
        };
    },
    'mounted': function () {
        this.refresh();
    },
    'methods': {
        refresh() {
            const _this = this;

            this.$axios.GET('/api/v1/core/project/template_cla').then(res => {
                if (res.code == 200) {
                    _this.loading = false;
                    _this.treeData = res.data;
                }
            });
        },
        renderContent(h, {
            node
        }) {
            return h('span', {
                'class': 'custom-tree-node'
            }, h('span', null, node.label));
        },
        handleNodeClick(data) {
            let _this = this;
            if (data.children != undefined) {
                _this.tableData = data.templates;
            }
        }
    }
};
</script>
<style scoped>
.el-table__row .cell {
  display: block;
}

.rules .el-row {
  justify-content: space-around;
}

</style>
<style>
.rules .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #c2dbf8;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.custom-tree-node > span > a {
  margin-left: 10px;
}

.left {
  border: 1px solid #ddd;
  padding: 10px;
}

.right {
  border: 1px solid #ddd;
}

</style>
